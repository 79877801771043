/*@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono&family=Babylonica&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono&family=Babylonica&family=Oleo+Script+Swash+Caps&display=swap');

*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --main-font: 'Azeret Mono', monospace;
  --secondary-font: 'Oleo Script Swash Caps', cursive;/* 'Babylonica', cursive;*/
  --main-font-color: #FFF;
  --secondary-font-color: #A7895E;
}

html{
	font-size: 10px;
	scroll-behavior: smooth;
	font-family: var(--main-font);
	color: var(--main-font-color);
	overflow-x: hidden;
}

img{
	width: 100%;
	max-width: 100%;
}

a{
	text-decoration: none;
  color: inherit;
}

body {
  background: rgb(0, 0, 0, 0.41) url(assets/photos/background.webp);
  background-blend-mode: darken;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 1rem 0rem 2rem;
}

.container {
  width: 100%;
  margin: 0px auto 0px;
  max-width: 55rem;
}

header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}

.line {
  width: 37%;
}

.logo {
  width: 26%;
}

.icon {
  background-image: url(assets/photos/icons.webp);
  width: 3rem;
  height: 3rem;
  background-size: 9rem 3rem;
}

.social-networks{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem 0px;
}

.instagram{
  background-position-x: 0rem;
}

.facebook {
  background-position-x: -3rem;
}

.phone {
  background-position-x: -6rem;
}

.welcome-text {
  font-family: var(--secondary-font);
  text-align: center;
  font-size: 6em;
  color: var(--secondary-font-color);
}

.category-selector {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0rem 2rem;
  font-size: 3em;
}

.category {
  text-align: center;
  height: 14rem;
  background-blend-mode: darken;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

footer {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.4em;
}

footer a {
  color: var(--secondary-font-color);
}

.menu-item-top {
  display: flex;
  justify-content: space-between;
}

.menu-item {
  display: flex;
  font-size: 2em;
  border-bottom: 2px dashed;
  margin-bottom: 0.6rem;
  flex-direction: column;
  padding-bottom: 0.2rem;
}

.menu-padding {
  padding: 0rem 2rem;
}

.menu-desc{
  font-size: 1.3rem;
  font-style: italic;
  color: #c1c1c1;
  padding-top: 0.5rem;
  width: 73%;
}

.category-loading {
  height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid white;
}

.admin-buttons {
  display: flex;
  flex-direction: row;
}

.admin-button {
  width: 50%;
  text-align: center;
  color: white;
  height: 3.2rem;
  font-size: 2rem;
}

.admin-title {
  text-align: center;
  font-size: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.admin-form{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-form-title {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.admin-submit {
  font-size: 2rem;
  background: #1c6e1c;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}

.admin-reset {
  font-size: 2rem;
  background: #8a9306;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}

.admin-add-category {
  padding: 0rem 2rem;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.subcategory-selector {
  padding: 0rem 2rem;
}

.admin-subcategory {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.admin-red {
  background: #871a1a;
}

.admin-green {
  background: #1c6e1c;
}